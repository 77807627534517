import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-confirm-alert/src/react-confirm-alert.css";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { confirmAlert } from "react-confirm-alert";
import Loading from "react-fullscreen-loading";

const AcceptedManu = () => {
  const userId = window.sessionStorage.getItem("id");
  const type = window.sessionStorage.getItem("type");

  const [loader, setLoader] = useState(false);

  const [seProManuListLoader, setSeProManuListLoader] = useState(false);

  const [seProManuscript, setSeProManuscript] = useState([]);
  const [manuscriptAuthors, setManuscriptAuthors] = useState([]);

  const [authorManuId, setAuthorManuId] = useState([]);
  const [authorShow, setAuthorShow] = useState(false);
  const authorModalClose = () => setAuthorShow(false);
  const authorModalShow = (authors, manuId) => {
    setAuthorManuId(manuId);
    setManuscriptAuthors(authors);
    setAuthorShow(true);
  };

  async function accManuscriptData() {
    try {
      setSeProManuListLoader(true);
      let datares = await fetch(window.api + "getAllMeAcceptedManuAdmin");
      datares = await datares.json();
      if (datares) {
        setSeProManuscript(datares.manuscripts);
        setSeProManuListLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    accManuscriptData();
  }, []);

  const getReviewingReport = async (manuId) => {
    setFetchRepLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);
    let result = await fetch(window.api + "getReviewingReport", {
      method: "POST",
      body: formData,
    });
    result = await result.json();
    if (result) {
      setFetchRepLoader(false);
      setRepManuData(result.all_reviewing);
    }
  };

  const [fetchRepLoader, setFetchRepLoader] = useState(false);
  const [repManuId, setRepManuId] = useState("");
  const [repManuFileId, setRepManuFileId] = useState("");
  const [repManuTitle, setRepManuTitle] = useState("");
  const [repManuYear, setRepManuYear] = useState("");
  const [repManuPno, setRepManuPno] = useState("");
  const [repManuData, setRepManuData] = useState([]);
  const [repShow, setRepShow] = useState(false);
  const repModalClose = () => {
    setRepShow(false);
    setRepManuId("");
    setRepManuFileId("");
    setRepManuTitle("");
    setRepManuYear("");
    setRepManuPno("");
    setRepManuData([]);
  };
  const repModalShow = async (
    manuId,
    manuFileId,
    manuTitle,
    manuYear,
    manuPno
  ) => {
    setRepShow(true);
    setRepManuId(manuId);
    setRepManuFileId(manuFileId);
    setRepManuTitle(manuTitle);
    setRepManuYear(manuYear);
    setRepManuPno(manuPno);

    getReviewingReport(manuId);
  };

  const [fileLoader, setFileLoader] = useState(false);
  const [rejLoader, setRejLoader] = useState(false);
  const [rejManuId, setRejManuId] = useState("");
  const [rejManuFileId, setRejManuFileId] = useState("");
  const [rejManuUserName, setRejManuUserName] = useState("");
  const [rejManuUserEmail, setRejManuUserEmail] = useState("");
  const [rejManuTitle, setRejManuTitle] = useState("");
  const [rejManuFile, setRejManuFile] = useState("");
  const [rejManuFilePath, setRejManuFilePath] = useState("");
  const [rejManuComments, setRejManuComments] = useState("");
  const [rejManuYear, setRejManuYear] = useState("");
  const [rejManuPno, setRejManuPno] = useState("");

  const [rejShow, setRejShow] = useState(false);
  const rejModalClose = () => {
    setRejShow(false);
    setRejManuFile("");
    setRejManuFilePath("");
    setRejManuId("");
    setRejManuFileId("");
    setRejManuComments("");
    setRejManuYear("");
    setRejManuPno("");
    accManuscriptData();
  };
  const rejModalShow = (
    manuId,
    manuFileId,
    manuTitle,
    manuYear,
    manuPno,
    manuUser,
    manuEmail
  ) => {
    setRejManuId(manuId);
    setRejManuFileId(manuFileId);
    setRejManuUserName(manuUser);
    setRejManuUserEmail(manuEmail);
    setRejManuTitle(manuTitle);
    const comments =
      "Dear " +
      manuUser +
      "! I am very much thankful to you for considering ‘International Journal of Agriculture and Biology’ (IJAB) to publish your scientific and professional achievements. However, I am sorry to inform you that your manuscript ‘" +
      manuTitle +
      "’and manuscript No. IJAB-" +
      manuYear +
      "-" +
      manuPno +
      " has been considered ‘unsuitable’ for publication in IJAB, however this rejection is based on comparison with high quality manuscripts submitted  to IJAB, and doesn’t mean the rejection of your research findings. You can submit it in any other journal more related to your findings. Looking forward for your future submissions. Best Regards";
    setRejManuComments(comments);
    setRejManuYear(manuYear);
    setRejManuPno(manuPno);
    setRejShow(true);
  };

  const handleReplyFile = async (file) => {
    setRejManuFile(file[0]);
    setFileLoader(true);
    const formData = new FormData();
    formData.append("replyFile", file[0]);
    try {
      let replyUploadData = await fetch(window.api + "uploadReply", {
        method: "POST",
        body: formData,
      });

      replyUploadData = await replyUploadData.json();
      console.warn(replyUploadData);
      if (replyUploadData.path) {
        setFileLoader(false);
        setRejManuFilePath(replyUploadData.path);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearReplyFile = () => {
    setRejManuFile("");
    setRejManuFilePath("");
  };

  async function rejToAuthor() {
    setRejLoader(true);
    const formData = new FormData();
    formData.append("rej_manu_id", rejManuId);
    formData.append("rej_manu_user_name", rejManuUserName);
    formData.append("rej_manu_user_email", rejManuUserEmail);
    formData.append("rej_manu_title", rejManuTitle);
    formData.append("rej_manu_file_id", rejManuFileId);
    formData.append("rej_manu_file_path", rejManuFilePath);
    formData.append("rej_manu_comments", rejManuComments);
    formData.append("rej_manu_year", rejManuYear);
    formData.append("rej_manu_pno", rejManuPno);
    formData.append("updated_by", userId);
    try {
      let rejData = await fetch(window.api + "rejToAuthorFromMe", {
        method: "POST",
        body: formData,
      });

      rejData = await rejData.json();
      if (rejData.success) {
        setRejLoader(false);
        rejModalClose();
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Issue Certificate

  const [cerLoader, setCerLoader] = useState(false);

  async function issueCertificate(assignId, manuId) {
    setCerLoader(true);
    const formData = new FormData();
    formData.append("cer_assign_id", assignId);

    try {
      let issueData = await fetch(window.api + "issueCertificate", {
        method: "POST",
        body: formData,
      });

      issueData = await issueData.json();
      if (issueData.success) {
        setCerLoader(false);
        getReviewingReport(manuId);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function reviseToSe(manuId, manuFileId, seAssignId, seName) {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to again send to " + seName + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => revise(manuId, manuFileId, seAssignId),
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function revise(manuId, manuFileId, seAssignId) {
    setLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);
    formData.append("manu_file_id", manuFileId);
    formData.append("se_assign_id", seAssignId);
    formData.append("updated_by", userId);

    try {
      let reviseData = await fetch(window.api + "reviseManuToSe", {
        method: "POST",
        body: formData,
      });

      reviseData = await reviseData.json();
      if (reviseData.success) {
        setLoader(false);
        accManuscriptData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [manuscriptReviewers, setManuscriptReviewers] = useState([]);
  const [reviewerManuId, setReviewerManuId] = useState("");
  const [reviewerShow, setReviewerShow] = useState(false);
  const [reviewerLoader, setReviewerLoader] = useState(false);
  const reviewerModalClose = () => {
    setReviewerShow(false);
    setReviewerManuId("");
    setManuscriptReviewers([]);
  };
  const reviewerModalShow = async (manuId) => {
    setReviewerManuId(manuId);
    setReviewerShow(true);
    setReviewerLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);

    try {
      let dataRes = await fetch(window.api + "getAllManuReviwers", {
        method: "POST",
        body: formData,
      });

      dataRes = await dataRes.json();
      if (dataRes.reviewers) {
        setReviewerLoader(false);
        setManuscriptReviewers(dataRes.reviewers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Galley Proof

  const [gpFile, setGpFile] = useState("");
  const [gpManuId, setGpManuId] = useState("");
  const [gpManuFileId, setGpManuFileId] = useState("");
  const [gpManuTitle, setGpManuTitle] = useState("");
  const [gpManuYear, setGpManuYear] = useState("");
  const [gpManuPno, setGpManuPno] = useState("");
  const [gpManuUser, setGpManuUser] = useState("");
  const [gpManuEmail, setGpManuEmail] = useState("");
  const [gpShow, setGpShow] = useState("");
  const [gpLoader, setGpLoader] = useState(false);

  const handleGpFile = async (file) => {
    setGpFile(file[0]);
  };

  const gpModalClose = () => {
    setGpShow(false);
    setGpManuId("");
    setGpManuFileId("");
    setGpManuTitle("");
    setGpManuYear("");
    setGpManuPno("");
    setGpManuUser("");
    setGpManuEmail("");
  };
  async function gpModalShow(
    manuId,
    manuFileId,
    manuTitle,
    manuYear,
    manuPno,
    manuUser,
    manuEmail
  ) {
    setGpShow(true);
    setGpManuId(manuId);
    setGpManuFileId(manuFileId);
    setGpManuTitle(manuTitle);
    setGpManuYear(manuYear);
    setGpManuPno(manuPno);
    setGpManuUser(manuUser);
    setGpManuEmail(manuEmail);
  }

  async function sendToGalleyProof(manuId) {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to send?",
      buttons: [
        {
          label: "Yes",
          onClick: () => galleyProofConfirmed(manuId),
        },
        {
          label: "No",
        },
      ],
    });
  }
  async function galleyProofConfirmed(manuId) {
    setLoader(true);
    const formData = new FormData();
    formData.append("gp_manu_id", manuId);
    formData.append("updated_by", userId);
    try {
      let gpData = await fetch(window.api + "sendToGalleyProofAdmin", {
        method: "POST",
        body: formData,
      });

      gpData = await gpData.json();
      if (gpData.success) {
        setLoader(false);
        accManuscriptData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Modal show={authorShow} onHide={authorModalClose}>
        <Modal.Header>
          <Modal.Title>Author Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <table className="table table-striped">
                <thead>
                  <th>Name</th>
                  <th>Affiliation</th>
                </thead>
                {authorManuId < 14245 ? (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.firstName + " " + ad.lastName} </td>
                            <td>{ad.affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.author_name}</td>
                            <td>{ad.author_affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={authorModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={repShow} size="xl" onHide={repModalClose}>
        <Modal.Header>
          <Modal.Title>Reviewing Report for Manuscript</Modal.Title>
          <Button variant="danger" onClick={repModalClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          <div className="scrollbar" id="style-1">
            <div className="container-fluid">
              {fetchRepLoader ? (
                <>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <i className="text-info">Fetching Record Please Wait..</i>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-2 col-2 p-0">
                      <img
                        alt="image"
                        src="assets/img/ijab-logo.png"
                        width="100px"
                      />
                    </div>
                    <div className="col-md-8 col-8 p-0 report-heading">
                      <h2>International Journal of Agriculture and Biology</h2>
                    </div>
                    <div className="col-md-2 col-2 p-0">
                      {repManuYear != 0 && repManuPno != 0 ? (
                        <>
                          <h5>
                            IJAB-{repManuYear}-{repManuPno}
                          </h5>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                        <h4>{repManuTitle}</h4>
                      </center>
                    </div>
                  </div>

                  {Object.keys(repManuData).length > 0 ? (
                    <>
                      {repManuData.map((m, index) => {
                        return (
                          <>
                            <Table
                              bordered
                              size="sm"
                              className="border border-dark"
                            >
                              <tbody>
                                <tr className="bg-dark">
                                  <td className="text-center" colSpan="2">
                                    {m.manu_file != null ? (
                                      <a
                                        href={
                                          window.storage +
                                          "downloadManuscript/" +
                                          m.manu_file
                                        }
                                        download={m.manu_file}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-white"
                                      >
                                        {/* <i className="fas fa-file-download"></i>Manu File */}
                                        <i className="fas fa-file-download"></i>{" "}
                                        {m.manu_file.substring(
                                          0,
                                          m.manu_file.lastIndexOf(".")
                                        )}
                                      </a>
                                    ) : (
                                      <i>No File</i>
                                    )}
                                    {m.file_status == 2 ? (
                                      <>
                                        <span className="text-white">
                                          {" "}
                                          (Eligible)
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                                {m.me_comments == null ||
                                m.me_comments == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Editor in Chief
                                      </td>
                                      <td colSpan={5}>
                                        {m.me_file != null ? (
                                          <a
                                            href={
                                              window.storage +
                                              "downloadReply/" +
                                              m.me_file
                                            }
                                            download={m.me_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                            Download Reply File
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                        <br />
                                        <b>Comments:</b> {m.me_comments}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {m.ret_se_comments == null ||
                                m.ret_se_comments == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <center>
                                          <b>Comments to Author</b>
                                        </center>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Section Editor
                                      </td>
                                      <td colSpan={5}>
                                        {m.ret_se_file != null ? (
                                          <a
                                            href={
                                              window.storage +
                                              "downloadReply/" +
                                              m.ret_se_file
                                            }
                                            download={m.ret_se_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                            Download Reply File
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                        <br />
                                        <b>Comments:</b> {m.ret_se_comments}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {m.edi_comments == null ||
                                m.edi_comments == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <center>
                                          <b>Comments to Author</b>
                                        </center>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Editorial Office
                                      </td>
                                      <td colSpan={5}>
                                        {m.edi_file != null ? (
                                          <a
                                            href={
                                              window.storage +
                                              "downloadReply/" +
                                              m.edi_file
                                            }
                                            download={m.edi_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                            Download Reply File
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                        <br />
                                        <b>Comments:</b> {m.edi_comments}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {m.se_accept == null || m.se_accept == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <center>
                                          <b>Comments to M.E</b>
                                        </center>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Section Editor
                                      </td>
                                      <td colSpan={5}>
                                        {m.se_file != null ? (
                                          <>
                                            <a
                                              href={
                                                window.storage +
                                                "downloadReply/" +
                                                m.se_file
                                              }
                                              download={m.se_file}
                                              rel="noreferrer"
                                              target="_blank"
                                            >
                                              <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                              {m.se_file.substring(
                                                0,
                                                m.se_file.lastIndexOf(".")
                                              )}
                                            </a>
                                            <br />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        <b>Accept Paper? </b>({m.se_accept})
                                        <br />
                                        <b>Comments:</b> {m.se_comments}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {Object.keys(m.assign_data).length > 0 ? (
                                  <>
                                    <tr>
                                      <th style={{ width: "9%" }}>
                                        Reviewed By
                                      </th>
                                      <th colSpan={5}>
                                        <center>Decision</center>
                                      </th>
                                    </tr>
                                    {m.assign_data.map((e, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              <b>
                                                {e.rev_fname}{" "}
                                                {e.rev_lname !== "s"
                                                  ? e.rev_lname
                                                  : ""}
                                              </b>
                                              <br />
                                              {e.rev_affiliation}
                                              <br />
                                              {e.created_at ? (
                                                e.created_at.substr(0, 10)
                                              ) : (
                                                <></>
                                              )}
                                              {e.assign_status == 3 ? (
                                                <>
                                                  {e.cer_status == 1 ? (
                                                    <>
                                                      <span className="text-success">
                                                        Certificate Issed
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        onClick={() =>
                                                          issueCertificate(
                                                            e.assign_id,
                                                            repManuId
                                                          )
                                                        }
                                                        className="btn btn-success btn-sm"
                                                      >
                                                        {cerLoader
                                                          ? "Issuing.."
                                                          : "Issue Certificate"}
                                                      </button>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                            <td colSpan={5}>
                                              {e.assign_status == 0 ? (
                                                <>
                                                  <i className="text-danger">
                                                    Reviewer Rejected Manuscript
                                                  </i>
                                                </>
                                              ) : e.assign_status == 1 ? (
                                                <>
                                                  <i className="text-warning">
                                                    Pending for Acceptance
                                                  </i>
                                                </>
                                              ) : e.assign_status == 2 ? (
                                                <>
                                                  <i className="text-info">
                                                    Decision Pending
                                                  </i>
                                                </>
                                              ) : e.assign_status == 3 ? (
                                                <>
                                                  <div className="row">
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Do you want get credit
                                                        for reviewing this
                                                        manuscript?
                                                      </b>{" "}
                                                      ({e.paid})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Subjects and contents
                                                        are suitable for
                                                        publications?
                                                      </b>{" "}
                                                      ({e.q1})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Novelty of results is
                                                        appropriate?
                                                      </b>{" "}
                                                      ({e.q2})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Conclusions are well
                                                        proven?
                                                      </b>{" "}
                                                      ({e.q3})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>Title is suitable?</b>{" "}
                                                      ({e.q4})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Number and quality of
                                                        references are
                                                        appropriate?
                                                      </b>{" "}
                                                      ({e.q5})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        The linguistic quality
                                                        of paper is good?
                                                      </b>{" "}
                                                      ({e.q6})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Reference is provided
                                                        where data are reported?
                                                      </b>{" "}
                                                      ({e.q7})
                                                    </div>
                                                  </div>
                                                  <hr />
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <b>Recomendations: </b>
                                                      {e.r === "r1" ? (
                                                        <>
                                                          <span>
                                                            Accept in Present
                                                            Form
                                                          </span>
                                                        </>
                                                      ) : e.r === "r2" ? (
                                                        <>
                                                          <span>
                                                            Consider After Minor
                                                            Revision
                                                          </span>
                                                        </>
                                                      ) : e.r === "r3" ? (
                                                        <>
                                                          <span>
                                                            Reassess After Major
                                                            Revision
                                                          </span>
                                                        </>
                                                      ) : e.r === "r4" ? (
                                                        <>
                                                          <span>Reject</span>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                    <div className="col-md-6">
                                                      {e.reply_file_path !=
                                                      null ? (
                                                        <a
                                                          href={
                                                            window.storage +
                                                            "downloadRevReply/" +
                                                            e.reply_file_path
                                                          }
                                                          download={
                                                            e.reply_file_path
                                                          }
                                                          rel="noreferrer"
                                                          target="_blank"
                                                          style={{
                                                            color: "#000000",
                                                          }}
                                                        >
                                                          <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                                          Download Reviewer
                                                          Reply File
                                                        </a>
                                                      ) : (
                                                        <a></a>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <hr />
                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <b>
                                                        Confidential Comments to
                                                        Executive Editor:{" "}
                                                      </b>
                                                      {e.ex_co}
                                                    </div>
                                                    <div className="col-sm-12">
                                                      <b>
                                                        Comments to the Author:{" "}
                                                      </b>
                                                      {e.aut_co}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <i>Not Replied Yet</i>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>No Data</td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </Table>
                            {/* <hr /> */}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>No Data</td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={rejShow} size="lg" onHide={rejModalClose}>
        <Modal.Header>
          <Modal.Title>Reject Manuscript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="formFile" className="form-label">
                    Reply File
                  </label>
                  {fileLoader ? (
                    <>
                      <p>
                        <i style={{ color: "red" }}>Uploading...</i>
                      </p>
                    </>
                  ) : (
                    <>
                      {rejManuFilePath ? (
                        <>
                          <p>
                            <Badge bg="success" style={{ color: "white" }}>
                              Reply File Uploaded
                            </Badge>{" "}
                            <i
                              onClick={clearReplyFile}
                              className="fas fa-times"
                            ></i>
                          </p>
                        </>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => handleReplyFile(e.target.files)}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="formFile" className="form-label">
                    Comments
                  </label>
                  <textarea
                    value={rejManuComments}
                    onChange={(e) => setRejManuComments(e.target.value)}
                    rows={11}
                    style={{ width: "100%", borderColor: "#E4E6FC" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={rejToAuthor}
            disabled={!rejManuComments || fileLoader}
          >
            {rejLoader ? "Rejecting! Please wait" : "Reject"}
          </Button>
          <Button variant="secondary" onClick={rejModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reviewerShow} size="xl" onHide={reviewerModalClose}>
        <Modal.Header>
          <Modal.Title>Reviewer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              {reviewerLoader ? (
                <>Fetching Loader ... </>
              ) : (
                <table className="table table-striped">
                  <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Affiliation</th>
                    <th>Country</th>
                  </thead>

                  {Object.keys(manuscriptReviewers).length > 0 ? (
                    manuscriptReviewers.map((r) => (
                      <tbody>
                        <tr>
                          <td>{r.name}</td>
                          <td>{r.email}</td>
                          <td>{r.affiliation}</td>
                          <td>{r.country_name}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <></>
                  )}
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={reviewerModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={gpShow} size="md" onHide={gpModalClose}>
        <Modal.Header>
          <Modal.Title>Send to Galley Proof</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <label htmlFor="formFile" className="form-label">
                Galley Proof File
              </label>
              <input
                value={gpFile}
                className="form-control"
                type="file"
                onChange={(e) => handleGpFile(e.target.files)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={sendToGalleyProof}
            disabled={!gpFile || gpLoader}
          >
            {gpLoader ? "Sending! Please wait" : "Send"}
          </Button>
          <Button variant="secondary" onClick={gpModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {loader ? (
        <>
          <Loading loading loaderColor="#22C7B8" />
        </>
      ) : (
        <></>
      )}
      {seProManuListLoader ? (
        <>
          <i>Fetching Record Please Wait....</i>
        </>
      ) : (
        <>
          {Object.keys(seProManuscript).length > 0 ? (
            <>
              <div className="table-responsive" style={{ height: "100%" }}>
                <table className="table table-sm table-striped table-bordered se-pro-manu">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Title / Author</th>
                      <th>File</th>
                      <th>APC</th>
                      {/* <th>Author</th>
                      <th>Publish</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seProManuscript.map((m) => (
                      <tr key={m}>
                        <td>
                          {m.manu_year == 0 && m.manu_pno == 0 ? (
                            m.manu_id
                          ) : (
                            <>{"IJAB-" + m.manu_year + "-" + m.manu_pno}</>
                          )}
                          <br />
                          {m.manu_date}
                          <br />
                          <span
                            className="icon-property-info"
                            title="View Reviewing Report"
                            onClick={() =>
                              repModalShow(
                                m.manu_id,
                                m.manu_file_id,
                                m.manu_title,
                                m.manu_year,
                                m.manu_pno
                              )
                            }
                          >
                            Report
                          </span>
                        </td>
                        <td>
                          {m.manu_title}
                          <br />

                          <span
                            className="icon-hand"
                            onClick={() =>
                              authorModalShow(m.manu_authors, m.manu_id)
                            }
                          >
                            <i className="fas fa-user"></i>
                            {Object.keys(m.manu_authors).length > 0 ? (
                              m.manu_authors.map((a) => <> {a.author_name},</>)
                            ) : (
                              <></>
                            )}
                          </span>
                          {/* <span
                            onClick={() => reviewerModalShow(m.manu_id)}
                            className="icon-hand"
                            style={{ float: "right" }}
                          >
                            Suggested Reviewers
                          </span> */}
                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <b>Corresponding: </b>
                              {m.manu_user_email}*
                            </div>
                            <div className="col-md-6">
                              <div className="float-end">
                                <b>Processed By: </b>
                                {Object.keys(m.manu_se).length > 0 ? (
                                  m.manu_se.map((s) => (
                                    <>
                                      <i
                                        className={
                                          s.se_manu_status == 0
                                            ? "text-danger"
                                            : s.se_manu_status == 3
                                            ? "text-success"
                                            : s.se_manu_status == 2
                                            ? "text-info"
                                            : ""
                                        }
                                      >
                                        {s.se_name}{" "}
                                      </i>
                                    </>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {m.manu_final_file ? (
                            <>
                              <a
                                href={
                                  window.storage +
                                  "downloadFinalFile/" +
                                  m.manu_final_file
                                }
                                target="_blank"
                                rel="noreferrer"
                                download={m.manu_final_file}
                              >
                                <i
                                  style={{ fontSize: "20px" }}
                                  className="fas fa-file-download"
                                ></i>
                              </a>
                            </>
                          ) : (
                            <>no file</>
                          )}
                        </td>

                        <td>
                          {m.manu_apc_file ? (
                            <a
                              href={
                                window.storage +
                                "downloadApcSlipFile/" +
                                m.manu_apc_file
                              }
                              target="_blank"
                              rel="noreferrer"
                              download={m.manu_apc_file}
                            >
                              <i
                                style={{ fontSize: "20px" }}
                                className="fas fa-file-download"
                              ></i>
                            </a>
                          ) : (
                            <>no file</>
                          )}
                        </td>
                        <td>
                          {type === "EO" ? (
                            <></>
                          ) : (
                            <>
                              {m.manu_need_apc == 1 ? (
                                <span className="text-danger">APC Pending</span>
                              ) : (
                                <>
                                  <button
                                    class="btn btn-success dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    Actions
                                  </button>
                                  <div class="dropdown-menu">
                                    <i
                                      className="dropdown-item i-tag icon-property-info"
                                      title="Accept Manuscript"
                                      onClick={() =>
                                        sendToGalleyProof(m.manu_id)
                                      }
                                    >
                                      Send for Galley Proof
                                    </i>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <i className="text-danger">No Data</i>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AcceptedManu;

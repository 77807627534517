import React, { useState, useEffect } from "react";
import Loading from "react-fullscreen-loading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const AllUsers = () => {
  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  async function userData() {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("list", 1);
      let result = await fetch(window.api + "getAllUsers", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result) {
        setLoader(false);
        setUserList(result.users);
        setSubjectList(result.subject_list);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    userData();
  }, []);

  const makeReviewer = async (userId) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to make Reviewer?",
      buttons: [
        {
          label: "Yes",
          onClick: () => makeR(userId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const makeR = async (userId) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("user_id", userId);
      let result = await fetch(window.api + "makeReviewer", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result.success) {
        userData();
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const terminateReviewer = async (userId) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to Terminate Reviewer?",
      buttons: [
        {
          label: "Yes",
          onClick: () => terminateR(userId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const terminateR = async (userId) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("user_id", userId);
      let result = await fetch(window.api + "terminateReviewer", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result.success) {
        userData();
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const makeSe = async (userId) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to make S.E?",
      buttons: [
        {
          label: "Yes",
          onClick: () => makeS(userId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const makeS = async (userId) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("user_id", userId);
      let result = await fetch(window.api + "makeSe", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result.success) {
        userData();
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const terminateSe = async (userId) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to Terminate S.E?",
      buttons: [
        {
          label: "Yes",
          onClick: () => terminateS(userId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const terminateS = async (userId) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("user_id", userId);
      let result = await fetch(window.api + "terminateSe", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result.success) {
        userData();
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [searchNameInput, setSearchNameInput] = useState("");
  const [searchSubjectInput, setSearchSubjectInput] = useState("0");

  const searchByName = async () => {
    // if (searchNameInput) {
      setLoader(true);
      try {
        const formData = new FormData();
        formData.append("user_name", searchNameInput);
        formData.append("subject", searchSubjectInput);
        let result = await fetch(window.api + "searchUserByName", {
          method: "POST",
          body: formData,
        });
        result = await result.json();
        if (result.users) {
          setLoader(false);
          setUserList(result.users);
        }
      } catch (err) {
        console.log(err);
      }
    // } else {
    //   userData();
    // }
  };
  return (
    <>
      {loader ? (
        <>
          <Loading loading loaderColor="#3498db" />
        </>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col-sm-3">
          <div className="input-group">
            <select
              value={searchSubjectInput}
              onChange={(e) => setSearchSubjectInput(e.target.value)}
              className="form-control"
            >
              <option value="0">--Select Speciality--</option>
              {Object.keys(subjectList).length > 0 ? (
                subjectList.map((s) => <option value={s.id}>{s.name}</option>)
              ) : (
                <></>
              )}
            </select>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="search by Name"
              onChange={(e) => setSearchNameInput(e.target.value)}
              value={searchNameInput}
            />
          </div>
        </div>

        <div className="col-sm-2">
          <button className="btn btn-info" onClick={searchByName} disabled={!searchNameInput && searchSubjectInput == "0"}>
            Search
          </button>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            {Object.keys(userList).length > 0 ? (
              <>
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Name / Specialization</th>
                      <th>Affiliation / Country</th>
                      <th>Mobile / Email</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((u, index) => (
                      <tr key={index}>
                        <td>
                          {u.title_name} {u.fname} {u.lname}
                          {u.rev_applied === "true" ? (
                            <i
                              className="i-tag fas fa-bookmark text-success pl-1"
                              title="Applied for Reviewer"
                            ></i>
                          ) : (
                            <></>
                          )}
                          <br />
                          {u.spec_name}
                        </td>
                        <td>
                          {u.affiliation}
                          <br />
                          {u.country_name}
                        </td>
                        <td>
                          {u.mobile}
                          <br />
                          {u.email}
                        </td>
                        <td>
                          {u.reviewer == 1 ? (
                            <>
                              <span>Reviewer</span>{" "}
                              <i
                                onClick={() => terminateReviewer(u.id)}
                                className="fas fa-ban i-tag"
                                title="Terminate"
                              ></i>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          {u.section_editor == 1 ? (
                            <>
                              <span>Section Editor</span>{" "}
                              <i
                                onClick={() => terminateSe(u.id)}
                                className="fas fa-ban i-tag"
                                title="Terminate"
                              ></i>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          {u.reviewer == 1 && u.section_editor == 1 ? (
                            <>No Action</>
                          ) : (
                            <>
                              <button
                                class="btn btn-success dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Actions
                              </button>
                              <div class="dropdown-menu">
                                {u.reviewer == 0 ? (
                                  <>
                                    <i
                                      class="dropdown-item i-tag"
                                      onClick={() => makeReviewer(u.id)}
                                    >
                                      Make Reviewer
                                    </i>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {u.section_editor == 0 ? (
                                  <>
                                    <i
                                      class="dropdown-item i-tag"
                                      onClick={() => makeSe(u.id)}
                                    >
                                      Make S.E
                                    </i>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <center>No Data</center>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUsers;

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Badge from "react-bootstrap/Badge";
import Loading from "react-fullscreen-loading";

const Manu = () => {
  const userId = window.sessionStorage.getItem("id");
  const type = window.sessionStorage.getItem("type");

  const [manuListLoader, setManuListLoader] = useState(false);

  const [manuscript, setManuscript] = useState([]);
  const [manuscriptAuthors, setManuscriptAuthors] = useState([]);
  const [specialityList, setSpecialityList] = useState([]);
  const [seList, setSeList] = useState([]);

  const [fileLoader, setFileLoader] = useState(false);
  const [returnLoader, setReturnLoader] = useState(false);
  const [returnManuId, setReturnManuId] = useState("");
  const [returnManuFileId, setReturnManuFileId] = useState("");
  const [returnManuUserName, setReturnManuUserName] = useState("");
  const [returnManuUserEmail, setReturnManuUserEmail] = useState("");
  const [returnManuTitle, setReturnManuTitle] = useState("");
  const [returnManuFile, setReturnManuFile] = useState("");
  const [returnManuFilePath, setReturnManuFilePath] = useState("");
  const [returnManuComments, setReturnManuComments] = useState("");
  const [returnManuYear, setReturnManuYear] = useState("");
  const [returnManuPno, setReturnManuPno] = useState("");

  const [seLoader, setSeLoader] = useState(false);
  const [seLoaderId, setSeLoaderId] = useState("");
  const [seSpeciality, setSeSpeciality] = useState("");
  const [seName, setSeName] = useState("");
  const [seManuId, setSeManuId] = useState("");
  const [seManuCorName, setSeManuCorName] = useState("");
  const [seManuTitle, setSeManuTitle] = useState("");
  const [seManuFileId, setSeManuFileId] = useState("");
  const [seManuYear, setSeManuYear] = useState("");
  const [seManuPno, setSeManuPno] = useState("");

  const [loader, setLoader] = useState(false);

  const [authorManuId, setAuthorManuId] = useState([]);
  const [authorShow, setAuthorShow] = useState(false);
  const authorModalClose = () => setAuthorShow(false);
  const authorModalShow = (authors, manuId) => {
    setAuthorManuId(manuId);
    setManuscriptAuthors(authors);
    setAuthorShow(true);
  };

  const [returnShow, setReturnShow] = useState(false);
  const returnModalClose = () => {
    setReturnShow(false);
    setReturnManuFile("");
    setReturnManuFilePath("");
    setReturnManuId("");
    setReturnManuFileId("");
    setReturnManuComments("");
    setReturnManuYear("");
    setReturnManuPno("");
    manuscriptData();
  };
  const returnModalShow = (
    manuId,
    manuUser,
    manuEmail,
    manuTitle,
    manuFileId,
    manuYear,
    manuPno
  ) => {
    setReturnManuId(manuId);
    setReturnManuFileId(manuFileId);
    setReturnManuUserName(manuUser);
    setReturnManuUserEmail(manuEmail);
    setReturnManuTitle(manuTitle);
    const comments =
      "Dear " +
      manuUser +
      "! I am very much thankful to you for considering ‘International Journal of Agriculture and Biology’ (IJAB) to publish your scientific and professional achievements. However, I am sorry to inform you that your manuscript ‘" +
      manuTitle +
      "’and manuscript No. IJAB-" +
      manuYear +
      "-" +
      manuPno +
      " has been considered ‘unsuitable’ for publication in IJAB and not being sent for review, however this rejection is based on comparison with high quality manuscripts submitted  to IJAB, and doesn’t mean the rejection of your research findings. You can submit it in any other journal more related to your findings. Looking forward for your future submissions. Best Regards";
    setReturnManuComments(comments);
    setReturnManuYear(manuYear);
    setReturnManuPno(manuPno);
    setReturnShow(true);
  };

  const [seShow, setSeShow] = useState(false);
  const seModalClose = () => {
    setSeShow(false);
    setSeManuId("");
    setSeManuCorName("");
    setSeManuTitle("");
    setSeManuFileId("");
    setSeManuYear("");
    setSeManuPno("");
    setSeSpeciality("");
    setSeList([]);
    setSeLoaderId("");
    setSeName("");
    manuscriptData();
  };
  const seModalShow = async (
    manuId,
    manuCorName,
    manuTitle,
    manuFileId,
    manuYear,
    manuPno
  ) => {
    setSeShow(true);
    setSeManuId(manuId);
    setSeManuCorName(manuCorName);
    setSeManuTitle(manuTitle);
    setSeManuFileId(manuFileId);
    setSeManuYear(manuYear);
    setSeManuPno(manuPno);
    let specRes = await fetch(window.api + "getSpeciality");
    specRes = await specRes.json();
    setSpecialityList(specRes.specialityList);
  };

  async function searchSeById(id) {
    setSeList([]);
    setSeLoader(true);
    setSeSpeciality(id);
    const formData = new FormData();
    formData.append("spec_id", id);
    try {
      let seRes = await fetch(window.api + "searchSeBySpecId", {
        method: "POST",
        body: formData,
      });

      seRes = await seRes.json();
      if (seRes) {
        setSeLoader(false);
        setSeList(seRes.seList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function searchSeByName(name) {
    setSeList([]);
    setSeLoader(true);
    setSeName(name);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("spec_id", seSpeciality);
    try {
      let seRes = await fetch(window.api + "searchSeByName", {
        method: "POST",
        body: formData,
      });

      seRes = await seRes.json();
      if (seRes) {
        setSeLoader(false);
        setSeList(seRes.seList);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function manuscriptData() {
    try {
      setManuListLoader(true);
      let datares = await fetch(window.api + "getAllMeManuAdmin");
      datares = await datares.json();
      if (datares) {
        setManuscript(datares.manuscripts);
        setManuListLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    manuscriptData();
  }, []);

  const handleReplyFile = async (file) => {
    setReturnManuFile(file[0]);
    setFileLoader(true);
    const formData = new FormData();
    formData.append("replyFile", file[0]);
    try {
      let replyUploadData = await fetch(window.api + "uploadReply", {
        method: "POST",
        body: formData,
      });

      replyUploadData = await replyUploadData.json();
      console.warn(replyUploadData);
      if (replyUploadData.path) {
        setFileLoader(false);
        setReturnManuFilePath(replyUploadData.path);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearReplyFile = () => {
    setReturnManuFile("");
    setReturnManuFilePath("");
  };

  async function sendToDme(manuId) {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to send to D.M.E?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Send(manuId),
        },
        {
          label: "No",
        },
      ],
    });
  }
  async function Send(manuId) {
    setLoader(true);
    const formData = new FormData();
    formData.append("senddme_manu_id", manuId);

    try {
      let sendRes = await fetch(window.api + "sendToDme", {
        method: "POST",
        body: formData,
      });

      sendRes = await sendRes.json();
      if (sendRes.success) {
        setLoader(false);
        manuscriptData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function cancleToMe(manuId, manuUserName, manuUserEmail, manuTitle) {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Cancle(manuId, manuUserName, manuUserEmail, manuTitle),
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function Cancle(manuId, manuUserName, manuUserEmail, manuTitle) {
    setLoader(true);
    const formData = new FormData();
    formData.append("cancle_manu_id", manuId);
    formData.append("cancle_manu_user_name", manuUserName);
    formData.append("cancle_manu_user_email", manuUserEmail);
    formData.append("cancle_manu_title", manuTitle);
    formData.append("updated_by", userId);
    try {
      let cancleRes = await fetch(window.api + "cancleToMe", {
        method: "POST",
        body: formData,
      });

      cancleRes = await cancleRes.json();
      if (cancleRes.success) {
        setLoader(false);
        manuscriptData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function returnToAuthor() {
    setReturnLoader(true);
    const formData = new FormData();
    formData.append("ret_manu_id", returnManuId);
    formData.append("ret_manu_user_name", returnManuUserName);
    formData.append("ret_manu_user_email", returnManuUserEmail);
    formData.append("ret_manu_title", returnManuTitle);
    formData.append("ret_manu_file_id", returnManuFileId);
    formData.append("ret_manu_file_path", returnManuFilePath);
    formData.append("ret_manu_comments", returnManuComments);
    formData.append("ret_manu_year", returnManuYear);
    formData.append("ret_manu_pno", returnManuPno);
    formData.append("updated_by", userId);
    try {
      let returnData = await fetch(window.api + "returnToAuthorFromMe", {
        method: "POST",
        body: formData,
      });

      returnData = await returnData.json();
      if (returnData.success) {
        setReturnLoader(false);

        returnModalClose();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function sendToSe(secId, secEmail, secName) {
    setSeLoader(true);
    setSeLoaderId(secId);
    const formData = new FormData();
    formData.append("se_manu_id", seManuId);
    formData.append("se_manu_cor_name", seManuCorName);
    formData.append("se_manu_title", seManuTitle);
    formData.append("se_manu_file_id", seManuFileId);
    formData.append("se_manu_year", seManuYear);
    formData.append("se_manu_pno", seManuPno);
    formData.append("se_id", secId);
    formData.append("se_email", secEmail);
    formData.append("se_name", secName);
    formData.append("updated_by", userId);
    formData.append("user_type", "M");

    try {
      let sendData = await fetch(window.api + "sendToSe", {
        method: "POST",
        body: formData,
      });

      sendData = await sendData.json();
      if (sendData.success) {
        setSeLoader(false);

        seModalClose();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [manuscriptReviewers, setManuscriptReviewers] = useState([]);
  const [reviewerManuId, setReviewerManuId] = useState("");
  const [reviewerShow, setReviewerShow] = useState(false);
  const [reviewerLoader, setReviewerLoader] = useState(false);
  const reviewerModalClose = () => {
    setReviewerShow(false);
    setReviewerManuId("");
    setManuscriptReviewers([]);
  };
  const reviewerModalShow = async (manuId) => {
    setReviewerManuId(manuId);
    setReviewerShow(true);
    setReviewerLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);

    try {
      let dataRes = await fetch(window.api + "getAllManuReviwers", {
        method: "POST",
        body: formData,
      });

      dataRes = await dataRes.json();
      if (dataRes.reviewers) {
        setReviewerLoader(false);
        setManuscriptReviewers(dataRes.reviewers);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {loader ? (
        <>
          <Loading loading loaderColor="#22C7B8" />
        </>
      ) : (
        <></>
      )}
      <Modal show={authorShow} onHide={authorModalClose}>
        <Modal.Header>
          <Modal.Title>Author Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <table className="table table-striped">
                <thead>
                  <th>Name</th>
                  <th>Affiliation</th>
                </thead>
                {authorManuId < 14245 ? (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.firstName + " " + ad.lastName} </td>
                            <td>{ad.affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.author_name}</td>
                            <td>{ad.author_affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={authorModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={returnShow} size="xl" onHide={returnModalClose}>
        <Modal.Header>
          <Modal.Title>Return Manuscript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="formFile" className="form-label">
                    Reply File
                  </label>
                  {fileLoader ? (
                    <>
                      <p>
                        <i style={{ color: "red" }}>Uploading...</i>
                      </p>
                    </>
                  ) : (
                    <>
                      {returnManuFilePath ? (
                        <>
                          <p>
                            <Badge bg="success" style={{ color: "white" }}>
                              Reply File Uploaded
                            </Badge>{" "}
                            <i
                              onClick={clearReplyFile}
                              className="fas fa-times"
                            ></i>
                          </p>
                        </>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => handleReplyFile(e.target.files)}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="col-md-12">
                  <label htmlFor="formFile" className="form-label">
                    Comments
                  </label>
                  <textarea
                    value={returnManuComments}
                    onChange={(e) => setReturnManuComments(e.target.value)}
                    rows={11}
                    style={{ width: "100%", borderColor: "#E4E6FC" }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={returnToAuthor}
            disabled={!returnManuComments || fileLoader}
          >
            {returnLoader ? "Returning! Please wait" : "Return"}
          </Button>
          <Button variant="secondary" onClick={returnModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={seShow} size="lg" onHide={seModalClose}>
        <Modal.Header>
          <Modal.Title>Send Manuscript to Section Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-md-4 p-1">
                  <select
                    value={seSpeciality}
                    onChange={(e) => searchSeById(e.target.value)}
                    className="form-control"
                  >
                    <option value="">--Select Speciality--</option>
                    {Object.keys(specialityList).length > 0 ? (
                      specialityList.map((s) => (
                        <option value={s.id}>{s.name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                <div className="col-md-6 p-1">
                  <input
                    type="text"
                    value={seName}
                    onChange={(e) => searchSeByName(e.target.value)}
                    className="form-control"
                    placeholder="Search Section Editor by name"
                  />
                </div>
                <div className="col-md-2 p-1"></div>
              </div>
              <div className="row">
                <table className="table table-striped table-sm">
                  {Object.keys(seList).length > 0 ? (
                    <>
                      <thead>
                        <tr>
                          <th>Name / Email</th>
                          <th>Affiliation</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {seList.map((s) => (
                          <tr>
                            <td>
                              {s.fname} {s.lname}
                              <br />
                              {s.email}
                            </td>
                            <td>{s.affiliation}</td>
                            <td>
                              <button
                                onClick={() => sendToSe(s.id, s.email, s.fname)}
                                className="btn btn-success btn-sm"
                                disabled={seLoader || seLoaderId}
                              >
                                {seLoader && seLoaderId == s.id
                                  ? "Sending.."
                                  : "send"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <table className="table text-center text-danger">
                        <tr>
                          <td>No Data</td>
                        </tr>
                      </table>
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={seModalClose}
            disabled={seLoader || seLoaderId}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reviewerShow} size="xl" onHide={reviewerModalClose}>
        <Modal.Header>
          <Modal.Title>Reviewer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              {reviewerLoader ? (
                <>Fetching Loader ... </>
              ) : (
                <table className="table table-striped">
                  <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Affiliation</th>
                    <th>Country</th>
                  </thead>

                  {Object.keys(manuscriptReviewers).length > 0 ? (
                    manuscriptReviewers.map((r) => (
                      <tbody>
                        <tr>
                          <td>{r.name}</td>
                          <td>{r.email}</td>
                          <td>{r.affiliation}</td>
                          <td>{r.country_name}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <></>
                  )}
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={reviewerModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {manuListLoader ? (
        <>
          <i>Fecthing Record Please Wait.....</i>
        </>
      ) : (
        <>
          {Object.keys(manuscript).length > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-sm table-striped table-bordered me-manu">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Title / Author</th>
                      <th>T_File</th>
                      <th>File</th>
                      <th>Author</th>
                      {/* <th>D.M.E</th> */}
                      <th>S.E</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manuscript.map((m) => (
                      <tr key={m}>
                        <td>
                          {m.manu_year == 0 && m.manu_pno == 0 ? (
                            m.manu_id
                          ) : (
                            <>{"IJAB-" + m.manu_year + "-" + m.manu_pno}</>
                          )}
                          <br />
                          {m.manu_date}
                        </td>
                        <td>
                          {m.manu_title}
                          <br />

                          <span
                            className="icon-hand"
                            onClick={() =>
                              authorModalShow(m.manu_authors, m.manu_id)
                            }
                          >
                            <i className="fas fa-user"></i>
                            {Object.keys(m.manu_authors).length > 0 ? (
                              m.manu_authors.map((a) => <> {a.author_name},</>)
                            ) : (
                              <></>
                            )}
                          </span>
                          <span
                            onClick={() => reviewerModalShow(m.manu_id)}
                            className="icon-hand text-info"
                            style={{ float: "right" }}
                          >
                            Suggested Reviewers
                          </span>
                        </td>
                        <td>
                          {m.manu_similarity != null ? (
                            <a
                              href={
                                window.storage +
                                "downloadTurnitin/" +
                                m.manu_turnitin_file
                              }
                              target="_blank"
                              rel="noreferrer"
                              download={m.manu_turnitin_file}
                            >
                              <i
                                style={{ fontSize: "20px" }}
                                className="fas fa-file-download"
                              ></i>
                            </a>
                          ) : (
                            <></>
                          )}
                          <br />
                          {m.manu_similarity != null ? (
                            <>{m.manu_similarity}%</>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <a
                            href={
                              window.storage +
                              "downloadManuscript/" +
                              m.manu_file
                            }
                            target="_blank"
                            rel="noreferrer"
                            download={m.manu_file}
                          >
                            <i
                              style={{ fontSize: "20px" }}
                              className="fas fa-file-download"
                            ></i>
                          </a>
                        </td>
                        <td>
                          {type === "EO" ? (
                            <></>
                          ) : (
                            <>
                              <i
                                className="icon-property-red"
                                title="Return to Author"
                                onClick={() =>
                                  returnModalShow(
                                    m.manu_id,
                                    m.manu_user_name,
                                    m.manu_user_email,
                                    m.manu_title,
                                    m.manu_file_id,
                                    m.manu_year,
                                    m.manu_pno
                                  )
                                }
                              >
                                return
                              </i>
                            </>
                          )}
                        </td>
                        {/* <td>
                                                    <i className="icon-property" title='Send to D.M.E' onClick={() => sendToDme(m.manu_id)}>Send</i>
                                                </td> */}
                        <td>
                          {type === "EO" ? (
                            <></>
                          ) : (
                            <>
                              <i
                                className="icon-property"
                                title="Send to S.E"
                                onClick={() =>
                                  seModalShow(
                                    m.manu_id,
                                    m.manu_cor_author_name,
                                    m.manu_title,
                                    m.manu_file_id,
                                    m.manu_year,
                                    m.manu_pno
                                  )
                                }
                              >
                                Send
                              </i>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <i className="text-danger">No Data</i>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Manu;

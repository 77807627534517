import React, { useState, useEffect } from "react";
import Loading from "react-fullscreen-loading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SectionEditors = () => {
  const [loader, setLoader] = useState(false);
  const [seList, setSeList] = useState([]);
  const [title, setTitle] = useState([]);
  const [special, setSpecial] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [country, setCountry] = useState([]);

  async function seData() {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("list", 1);
      let result = await fetch(window.api + "getAllSectionEditors", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result) {
        setLoader(false);
        setSeList(result.section_editors);
        setTitle(result.title);
        setCountry(result.countries);
        setSpecial(result.specialization);
        setSubjectList(result.specialization);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    seData();
  }, []);

  const terminateSe = async (userId) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure want to Terminate S.E?",
      buttons: [
        {
          label: "Yes",
          onClick: () => terminateS(userId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const terminateS = async (userId) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("user_id", userId);
      let result = await fetch(window.api + "terminateSe", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result.success) {
        seData();
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //get data from form
  const [inputId, setInputId] = useState("");
  const [inputTitle, setInputTitle] = useState("");
  const [inputCountry, setInputCountry] = useState("");
  const [inputfname, setInputFname] = useState("");
  const [inputlname, setInputLname] = useState("");
  const [inputspecial, setInputSpecial] = useState("");
  const [inputaffiliation, setInputAffiliation] = useState("");
  const [inputmobile, setInputMobile] = useState("");
  const [inputemail, setInputEmail] = useState("");
  const [inputpass, setInputPass] = useState("");
  const [errorText, setErrorText] = useState("");
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  const register = async () => {
    setLoader(true);
    const formData = new FormData();
    // formData.append("id", inputId);
    formData.append("fname", inputfname);
    formData.append("lname", inputlname);
    formData.append("title", inputTitle);
    formData.append("country", inputCountry);
    formData.append("specialization", inputspecial);
    formData.append("affiliation", inputaffiliation);
    formData.append("mobile", inputmobile);
    formData.append("email", inputemail);
    formData.append("password", inputpass);

    try {
      let userData = await fetch(window.api + "addSectionEditor", {
        method: "POST",
        body: formData,
      });

      userData = await userData.json();
      if (userData.already) {
        setLoader(false);
        setSuccess(false);
        setErrorText(userData.already);
      } else {
        setLoader(false);
        setSuccess(true);
        setErrorText("");
        // setInputId("");
        setInputTitle("");
        setInputCountry("");
        setInputFname("");
        setInputLname("");
        setInputSpecial("");
        setInputAffiliation("");
        setInputMobile("");
        setInputEmail("");
        setInputPass("");
        seData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [searchNameInput, setSearchNameInput] = useState("");
  const [searchSubjectInput, setSearchSubjectInput] = useState("0");

  const searchByName = async () => {
    // if (searchNameInput) {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("user_name", searchNameInput);
      formData.append("subject", searchSubjectInput);
      let result = await fetch(window.api + "searchSectionEditorByName", {
        method: "POST",
        body: formData,
      });
      result = await result.json();
      if (result.section_editors) {
        setLoader(false);
        setSeList(result.section_editors);
      }
    } catch (err) {
      console.log(err);
    }
    // } else {
    //   seData();
    // }
  };

  async function extractUser() {
    setLoader(true);
    const formData = new FormData();
    formData.append("email", inputemail);

    try {
      let userData = await fetch(window.api + "extractUserByEmail", {
        method: "POST",
        body: formData,
      });

      userData = await userData.json();
      if (userData.user) {
        setLoader(false);
        setInputTitle(userData.user.title);
        setInputCountry(userData.user.country);
        setInputFname(userData.user.fname);
        setInputLname(userData.user.lname);
        setInputSpecial(userData.user.specialization);
        setInputAffiliation(userData.user.affiliation);
        setInputMobile(userData.user.mobile);
        setInputEmail(userData.user.email);
        setInputId(userData.user.id);
      } else if (userData.error) {
        setLoader(false);
        setSuccess(false);
        setErrorText(userData.error);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [editUserId, setEditUserId] = useState("");
  const [editUserTitle, setEditUserTitle] = useState("");
  const [editUserCountry, setEditUserCountry] = useState("");
  const [editUserSpecialization, setEditUserSpecialization] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [editUserFname, setEditUserFname] = useState("");
  const [editUserLname, setEditUserLname] = useState("");
  const [editUserAffiliation, setEditUserAffiliation] = useState("");
  const [editUserMobile, setEditUserMobile] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);

  const editModal = async (
    userId,
    userTitle,
    userCountry,
    userSpecialization,
    userEmail,
    userFname,
    userLname,
    userAffiliation,
    userMobile
  ) => {
    setEditUserId(userId);
    setEditUserTitle(userTitle);
    setEditUserCountry(userCountry);
    setEditUserSpecialization(userSpecialization);
    setEditUserEmail(userEmail);
    setEditUserFname(userFname);
    setEditUserLname(userLname);
    setEditUserAffiliation(userAffiliation);
    setEditUserMobile(userMobile);
    setShowEditModal(true);
  };

  const closeEditModal = async () => {
    setEditUserId("");
    setEditUserTitle("");
    setEditUserCountry("");
    setEditUserSpecialization("");
    setEditUserEmail("");
    setEditUserFname("");
    setEditUserLname("");
    setEditUserAffiliation("");
    setEditUserMobile("");
    setShowEditModal(false);
  };

  const [editLoader, setEditLoader] = useState(false);

  const editReviewer = async () => {
    setEditLoader(true);
    const formData = new FormData();
    formData.append("id", editUserId);
    formData.append("fname", editUserFname);
    formData.append("lname", editUserLname);
    formData.append("title", editUserTitle);
    formData.append("country", editUserCountry);
    formData.append("specialization", editUserSpecialization);
    formData.append("affiliation", editUserAffiliation);
    formData.append("mobile", editUserMobile);

    try {
      let userData = await fetch(window.api + "editSectionEditor", {
        method: "POST",
        body: formData,
      });

      userData = await userData.json();
      if (userData.error) {
        setEditLoader(false);
        setSuccess(false);
        setErrorText(userData.error);
        setSuccessText("");
      } else {
        setEditLoader(false);
        setSuccess(true);
        setSuccessText(userData.success);
        setErrorText("");
        closeEditModal();
        seData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal show={showEditModal} onHide={closeEditModal}>
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12 mt-1 p-1">
              <label>Email</label>
              <input
                type="text"
                className="form-control pwstrength"
                placeholder="Email Address"
                onChange={(e) => setEditUserEmail(e.target.value)}
                value={editUserEmail}
                data-indicator="pwindicator"
                disabled
              />
            </div>
            <div className="col-md-5 col-12 mt-1 p-1">
              <label>Title</label>
              <select
                className="form-control"
                onChange={(e) => setEditUserTitle(e.target.value)}
                value={editUserTitle}
              >
                <option value="">Select title</option>
                {title.map((e) => {
                  return (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-7 col-12 mt-1 p-1">
              <label>Country</label>
              <select
                className="form-control"
                onChange={(e) => setEditUserCountry(e.target.value)}
                value={editUserCountry}
              >
                <option value="">Select Country</option>
                {country.map((e) => {
                  return (
                    <option value={e.id} key={e.id}>
                      {e.country_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-12 col-12 mt-1 p-1">
              <label>Specialization</label>
              <select
                name=""
                id="special"
                className="form-control"
                onChange={(e) => setEditUserSpecialization(e.target.value)}
                value={editUserSpecialization}
              >
                <option value="">Select Specialization</option>
                {special.map((e) => {
                  return (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-6 col-12 mt-1 p-1">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="First Name "
                id="fname"
                onChange={(e) => setEditUserFname(e.target.value)}
                value={editUserFname}
              />
            </div>
            <div className="col-md-6 col-12 mt-1 p-1">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Last Name "
                id="lname"
                onChange={(e) => setEditUserLname(e.target.value)}
                value={editUserLname}
              />
            </div>
            <div className="col-md-12 col-12 mt-1">
              <label>Affiliation</label>
              <input
                className="form-control"
                type="text"
                placeholder="your affiliation"
                id="affiliation"
                onChange={(e) => setEditUserAffiliation(e.target.value)}
                value={editUserAffiliation}
              />
            </div>
            <div className="col-md-12 col-12 mt-1">
              <label>Mobile</label>
              <input
                className="form-control"
                type="number"
                placeholder="Mobile Number"
                id="phone"
                onChange={(e) => setEditUserMobile(e.target.value)}
                value={editUserMobile}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={editReviewer}
            disabled={
              !editUserTitle ||
              !editUserFname ||
              !editUserSpecialization ||
              !editUserCountry ||
              !editUserLname ||
              !editUserAffiliation ||
              !editUserMobile ||
              editLoader
            }
          >
            {editLoader ? "Updating! Please wait" : "Update"}
          </Button>
          <Button variant="secondary" onClick={closeEditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {loader ? (
        <>
          <Loading loading loaderColor="#3498db" />
        </>
      ) : (
        <></>
      )}
      {success ? (
        <>
          <div className="alert alert-success">
            {successText}
          </div>
        </>
      ) : (
        <></>
      )}
      {errorText ? (
        <>
          <div className="alert alert-danger">{errorText}</div>
        </>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col-md-3 p-1">
          <select
            className="form-control"
            onChange={(e) => setInputTitle(e.target.value)}
            value={inputTitle}
          >
            <option value="">Select title</option>
            {title.map((e) => {
              return (
                <option value={e.id} key={e.id}>
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-3 p-1">
          <select
            className="form-control"
            onChange={(e) => setInputCountry(e.target.value)}
            value={inputCountry}
          >
            <option value="">Select Country</option>
            {country.map((e) => {
              return (
                <option value={e.id} key={e.id}>
                  {e.country_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-3 p-1">
          <select
            name=""
            id="special"
            className="form-control"
            onChange={(e) => setInputSpecial(e.target.value)}
            value={inputspecial}
          >
            <option value="">Select Specialization</option>
            {special.map((e) => {
              return (
                <option value={e.id} key={e.id}>
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-3 p-1">
          {/* <div className="input-group"> */}
          <input
            type="text"
            className="form-control pwstrength"
            placeholder="Email Address"
            onChange={(e) => setInputEmail(e.target.value)}
            value={inputemail}
            data-indicator="pwindicator"
          />
          {/* <div className="input-group-prepend">
              <div className="input-group-text" onClick={extractUser}>
                Extract
              </div>
            </div> */}
          {/* </div> */}
        </div>
        <div className="col-md-3 p-1">
          <input
            className="form-control"
            type="text"
            placeholder="First Name "
            id="fname"
            onChange={(e) => setInputFname(e.target.value)}
            value={inputfname}
          />
        </div>
        <div className="col-md-3 p-1">
          <input
            className="form-control"
            type="text"
            placeholder="Last Name "
            id="lname"
            onChange={(e) => setInputLname(e.target.value)}
            value={inputlname}
          />
        </div>

        <div className="col-md-6 p-1">
          <input
            className="form-control"
            type="text"
            placeholder="your affiliation"
            id="affiliation"
            onChange={(e) => setInputAffiliation(e.target.value)}
            value={inputaffiliation}
          />
        </div>
        <div className="col-md-3 p-1">
          <input
            className="form-control"
            type="number"
            placeholder="Mobile Number"
            id="phone"
            onChange={(e) => setInputMobile(e.target.value)}
            value={inputmobile}
          />
        </div>
        {/* {inputId ? (
          <></>
        ) : (
          <> */}
        <div className="col-md-3 p-1">
          <input
            className="form-control"
            type="password"
            placeholder="Your Password"
            id="pass"
            onChange={(e) => setInputPass(e.target.value)}
            value={inputpass}
          />
        </div>
        {/* </>
        )} */}
        <div className="col-md-1 pt-1 text-center">
          <button
            type="button"
            className="btn btn-success"
            disabled={
              !inputTitle ||
              !inputfname ||
              !inputspecial ||
              !inputemail ||
              !inputCountry ||
              !inputlname ||
              !inputaffiliation ||
              !inputmobile ||
              !inputpass
            }
            onClick={register}
          >
            Save
          </button>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-3">
              <div className="input-group">
                <select
                  value={searchSubjectInput}
                  onChange={(e) => setSearchSubjectInput(e.target.value)}
                  className="form-control"
                >
                  <option value="0">--Select Speciality--</option>
                  {Object.keys(subjectList).length > 0 ? (
                    subjectList.map((s) => (
                      <option value={s.id}>{s.name}</option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="search by Name"
                  onChange={(e) => setSearchNameInput(e.target.value)}
                  value={searchNameInput}
                />
              </div>
            </div>
            <div className="col-sm-2">
              <button
                className="btn btn-info"
                onClick={searchByName}
                disabled={!searchNameInput && searchSubjectInput == "0"}
              >
                Search
              </button>
            </div>
          </div>
          <br />
          <div className="table-responsive">
            {Object.keys(seList).length > 0 ? (
              <>
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Name / Specialization</th>
                      <th>Affiliation / Country</th>
                      <th>Mobile / Email</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seList.map((u, index) => (
                      <tr key={index}>
                        <td>
                          {u.title_name} {u.fname} {u.lname}
                          {u.rev_applied === "true" ? (
                            <i
                              className="i-tag fas fa-bookmark text-success pl-1"
                              title="Applied for Reviewer"
                            ></i>
                          ) : (
                            <></>
                          )}
                          <br />
                          {u.spec_name}
                        </td>
                        <td>
                          {u.affiliation}
                          <br />
                          {u.country_name}
                        </td>
                        <td>
                          {u.mobile}
                          <br />
                          {u.email}
                        </td>
                        <td>
                          {u.section_editor == 1 ? (
                            <>
                              <span>S.E</span>{" "}
                              <i
                                onClick={() => terminateSe(u.id)}
                                className="fas fa-ban i-tag"
                                title="Terminate"
                              ></i>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <i
                            className="text-info i-tag"
                            onClick={() =>
                              editModal(
                                u.id,
                                u.title,
                                u.country,
                                u.specialization,
                                u.email,
                                u.fname,
                                u.lname,
                                u.affiliation,
                                u.mobile,
                                u.password
                              )
                            }
                          >
                            Edit
                          </i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <center>No Data</center>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionEditors;

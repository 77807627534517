import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-confirm-alert/src/react-confirm-alert.css";

const RetManu = () => {
  const [retManuListLoader, setRetManuListLoader] = useState(false);

  const [retManuscript, setRetManuscript] = useState([]);
  const [manuscriptAuthors, setManuscriptAuthors] = useState([]);
  const [returnReplyFile, setReturnReplyFile] = useState("");
  const [returnManuComments, setReturnManuComments] = useState("");

  const [authorManuId, setAuthorManuId] = useState([]);
  const [authorShow, setAuthorShow] = useState(false);
  const authorModalClose = () => setAuthorShow(false);
  const authorModalShow = (authors, manuId) => {
    setAuthorManuId(manuId);
    setManuscriptAuthors(authors);
    setAuthorShow(true);
  };

  const [commentShow, setCommentShow] = useState(false);
  const commentsModalClose = () => {
    setCommentShow(false);
    setReturnReplyFile("");
    setReturnManuComments("");
  };
  const commentsModalShow = (manuComments, manuFile) => {
    setReturnManuComments(manuComments);
    setReturnReplyFile(manuFile);
    setCommentShow(true);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    manuscriptDataDateWise();
  }, []);

  async function manuscriptDataDateWise() {
    setRetManuListLoader(true);
    const formData = new FormData();
    formData.append("from", from);
    formData.append("to", to);

    try {
      let dataRes = await fetch(window.api + "getAllMeRetManuAdmin", {
        method: "POST",
        body: formData,
      });

      dataRes = await dataRes.json();
      if (dataRes.manuscripts) {
        setRetManuListLoader(false);
        setRetManuscript(dataRes.manuscripts);
      }
    } catch (err) {
      console.log(err);
    }
  }

  var curr = new Date();
  curr.setDate(curr.getDate() - 30);
  var date = curr.toISOString().substr(0, 10);

  var currto = new Date();
  var todate = currto.toISOString().substr(0, 10);

  const [from, setFrom] = useState(date);
  const [to, setTo] = useState(todate);

  const [manuscriptReviewers, setManuscriptReviewers] = useState([]);
  const [reviewerManuId, setReviewerManuId] = useState("");
  const [reviewerShow, setReviewerShow] = useState(false);
  const [reviewerLoader, setReviewerLoader] = useState(false);
  const reviewerModalClose = () => {
    setReviewerShow(false);
    setReviewerManuId("");
    setManuscriptReviewers([]);
  };
  const reviewerModalShow = async (manuId) => {
    setReviewerManuId(manuId);
    setReviewerShow(true);
    setReviewerLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);

    try {
      let dataRes = await fetch(window.api + "getAllManuReviwers", {
        method: "POST",
        body: formData,
      });

      dataRes = await dataRes.json();
      if (dataRes.reviewers) {
        setReviewerLoader(false);
        setManuscriptReviewers(dataRes.reviewers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal show={authorShow} onHide={authorModalClose}>
        <Modal.Header>
          <Modal.Title>Author Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <table className="table table-striped">
                <thead>
                  <th>Name</th>
                  <th>Affiliation</th>
                </thead>
                {authorManuId < 14245 ? (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.firstName + " " + ad.lastName} </td>
                            <td>{ad.affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.author_name}</td>
                            <td>{ad.author_affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={authorModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={commentShow} size="xl" onHide={commentsModalClose}>
        <Modal.Header>
          <Modal.Title>Comments For manuscriptAuthors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-md-12">
                  {returnManuComments !== "" ? (
                    <>
                      <p>
                        <b>M.E Comments: </b>
                        {returnManuComments}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                  {returnReplyFile !== "" ? (
                    <>
                      <p>
                        <b>M.E Reply File: </b>
                        <a
                          href={
                            window.storage + "downloadReply/" + returnReplyFile
                          }
                          target="_blank"
                          rel="noreferrer"
                          download={returnReplyFile}
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            className="fas fa-file-download"
                          ></i>{" "}
                          Download Reply File
                        </a>
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={commentsModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={reviewerShow} size="xl" onHide={reviewerModalClose}>
        <Modal.Header>
          <Modal.Title>Reviewer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              {reviewerLoader ? (
                <>Fetching Loader ... </>
              ) : (
                <table className="table table-striped">
                  <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Affiliation</th>
                    <th>Country</th>
                  </thead>

                  {Object.keys(manuscriptReviewers).length > 0 ? (
                    manuscriptReviewers.map((r) => (
                      <tbody>
                        <tr>
                          <td>{r.name}</td>
                          <td>{r.email}</td>
                          <td>{r.affiliation}</td>
                          <td>{r.country_name}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <></>
                  )}
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={reviewerModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <div className="row mt-1">
          <div className="col-sm-3">
            <div className="form-group">
              <input
                type="date"
                className="form-control form-control-sm"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <input
                type="date"
                className="form-control form-control-sm"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <button
              type="button"
              onClick={manuscriptDataDateWise}
              className="btn btn-info btn-sm"
            >
              Get Record
            </button>
          </div>
        </div>
      </div>
      {retManuListLoader ? (
        <>
          <i>Fetching Record Please Wait....</i>
        </>
      ) : (
        <>
          {Object.keys(retManuscript).length > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-sm table-striped table-bordered ret-manu">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Title / Author</th>
                      <th>File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {retManuscript.map((m) => (
                      <tr key={m}>
                        <td>
                          {m.manu_year == 0 && m.manu_pno == 0 ? (
                            m.manu_id
                          ) : (
                            <>{"IJAB-" + m.manu_year + "-" + m.manu_pno}</>
                          )}
                          <br />
                          {m.manu_date}<br />
                          <span
                            className="icon-property-info"
                            title="View Comments"
                            onClick={() =>
                              commentsModalShow(
                                m.manu_file_me_comnts,
                                m.manu_file_me_rep
                              )
                            }
                          >
                            Comments
                          </span>
                        </td>
                        <td>
                          {m.manu_title}
                          <br />

                          <span
                            className="icon-hand"
                            onClick={() =>
                              authorModalShow(m.manu_authors, m.manu_id)
                            }
                          >
                            <i className="fas fa-user"></i>
                            {Object.keys(m.manu_authors).length > 0 ? (
                              m.manu_authors.map((a) => <> {a.author_name},</>)
                            ) : (
                              <></>
                            )}
                          </span>
                          <span
                            onClick={() => reviewerModalShow(m.manu_id)}
                            className="icon-hand"
                            style={{ float: "right" }}
                          >
                            Suggested Reviewers
                          </span>
                        </td>
                        <td>
                          <a
                            href={
                              window.storage +
                              "downloadManuscript/" +
                              m.manu_file
                            }
                            target="_blank"
                            rel="noreferrer"
                            download={m.manu_file}
                          >
                            <i
                              style={{ fontSize: "20px" }}
                              className="fas fa-file-download"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <i className="text-danger">No Data</i>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RetManu;

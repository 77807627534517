import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./component/Dashboard";
import Login from "./component/Login";
import DMEManuscripts from "./component/DMEManuscripts";
import EICManuscripts from "./component/EICManuscrips";
import Inpres from "./component/Inpres";
import ManageUser from "./component/ManageUser";
import Manuscripts  from "./component/Manuscripts";
import MEManuscripts  from "./component/MEManuscripts";
import Misc  from "./component/Misc";
import Publications  from "./component/Publications";
import PublishPaper  from "./component/PublishPaper";
import Users  from "./component/Users";

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/DMEManuscripts" element={<DMEManuscripts />} />
        <Route path="/EICManuscripts" element={<EICManuscripts />} />
        <Route path="/Inpres" element={<Inpres />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/PublishPaper" element={<PublishPaper />} />
        <Route path="/Publications" element={<Publications />} />
        <Route path="/Misc" element={<Misc />} />
        <Route path="/MEManuscripts" element={<MEManuscripts />} />
        <Route path="/Manuscripts" element={<Manuscripts />} />
        <Route path="/ManageUser" element={<ManageUser />} />
      </Routes>
    </>
  );
}

export default App;
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-confirm-alert/src/react-confirm-alert.css";
import Table from "react-bootstrap/Table";

const RejManu = () => {
  const [rejManuListLoader, setRejManuListLoader] = useState(false);

  const [rejManuscript, setRejManuscript] = useState([]);
  const [manuscriptAuthors, setManuscriptAuthors] = useState([]);

  const [authorManuId, setAuthorManuId] = useState([]);
  const [authorShow, setAuthorShow] = useState(false);
  const authorModalClose = () => setAuthorShow(false);
  const authorModalShow = (authors, manuId) => {
    setAuthorManuId(manuId);
    setManuscriptAuthors(authors);
    setAuthorShow(true);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    manuscriptDataDateWise();
  }, []);

  const getReviewingReport = async (manuId) => {
    setFetchRepLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);
    let result = await fetch(window.api + "getReviewingReport", {
      method: "POST",
      body: formData,
    });
    result = await result.json();
    if (result) {
      setFetchRepLoader(false);
      setRepManuData(result.all_reviewing);
    }
  };

  const [fetchRepLoader, setFetchRepLoader] = useState(false);
  const [repManuId, setRepManuId] = useState("");
  const [repManuFileId, setRepManuFileId] = useState("");
  const [repManuTitle, setRepManuTitle] = useState("");
  const [repManuYear, setRepManuYear] = useState("");
  const [repManuPno, setRepManuPno] = useState("");
  const [repManuData, setRepManuData] = useState([]);
  const [repShow, setRepShow] = useState(false);
  const repModalClose = () => {
    setRepShow(false);
    setRepManuId("");
    setRepManuFileId("");
    setRepManuTitle("");
    setRepManuYear("");
    setRepManuPno("");
    setRepManuData([]);
  };
  const repModalShow = async (
    manuId,
    manuFileId,
    manuTitle,
    manuYear,
    manuPno
  ) => {
    setFetchRepLoader(true);
    setRepShow(true);
    setRepManuId(manuId);
    setRepManuFileId(manuFileId);
    setRepManuTitle(manuTitle);
    setRepManuYear(manuYear);
    setRepManuPno(manuPno);

    getReviewingReport(manuId);
    // const formData = new FormData
    // formData.append('manu_id', manuId)
    // let result = await fetch(window.api + "getReviewingReport", {
    //     method: 'POST',
    //     body: formData
    // })
    // result = await result.json()
    // if (result) {
    //     setFetchRepLoader(false)
    //     setRepManuData(result.all_reviewing)
    // }
  };

  var curr = new Date();
  curr.setDate(curr.getDate() - 30);
  var date = curr.toISOString().substr(0, 10);

  var currto = new Date();
  var todate = currto.toISOString().substr(0, 10);

  const [from, setFrom] = useState(date);
  const [to, setTo] = useState(todate);

  async function manuscriptDataDateWise() {
    setRejManuListLoader(true);
    const formData = new FormData();
    formData.append("from", from);
    formData.append("to", to);

    try {
      let dataRes = await fetch(window.api + "getAllMeRejManuAdmin", {
        method: "POST",
        body: formData,
      });

      dataRes = await dataRes.json();
      if (dataRes.rejected_manu) {
        setRejManuListLoader(false);
        setRejManuscript(dataRes.rejected_manu);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Issue Certificate

  const [cerLoader, setCerLoader] = useState(false);

  async function issueCertificate(assignId, manuId) {
    setCerLoader(true);
    const formData = new FormData();
    formData.append("cer_assign_id", assignId);

    try {
      let issueData = await fetch(window.api + "issueCertificate", {
        method: "POST",
        body: formData,
      });

      issueData = await issueData.json();
      if (issueData.success) {
        setCerLoader(false);
        getReviewingReport(manuId);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [manuscriptReviewers, setManuscriptReviewers] = useState([]);
  const [reviewerManuId, setReviewerManuId] = useState("");
  const [reviewerShow, setReviewerShow] = useState(false);
  const [reviewerLoader, setReviewerLoader] = useState(false);
  const reviewerModalClose = () => {
    setReviewerShow(false);
    setReviewerManuId("");
    setManuscriptReviewers([]);
  };
  const reviewerModalShow = async (manuId) => {
    setReviewerManuId(manuId);
    setReviewerShow(true);
    setReviewerLoader(true);
    const formData = new FormData();
    formData.append("manu_id", manuId);

    try {
      let dataRes = await fetch(window.api + "getAllManuReviwers", {
        method: "POST",
        body: formData,
      });

      dataRes = await dataRes.json();
      if (dataRes.reviewers) {
        setReviewerLoader(false);
        setManuscriptReviewers(dataRes.reviewers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal show={authorShow} onHide={authorModalClose}>
        <Modal.Header>
          <Modal.Title>Author Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <table className="table table-striped">
                <thead>
                  <th>Name</th>
                  <th>Affiliation</th>
                </thead>
                {authorManuId < 14245 ? (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.firstName + " " + ad.lastName} </td>
                            <td>{ad.affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {Object.keys(manuscriptAuthors).length > 0 ? (
                      manuscriptAuthors.map((ad) => (
                        <tbody>
                          <tr>
                            <td>{ad.author_name}</td>
                            <td>{ad.author_affiliation}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={authorModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={repShow} size="xl" onHide={repModalClose}>
        <Modal.Header>
          <Modal.Title>Reviewing Report for Manuscript</Modal.Title>
          <Button variant="danger" onClick={repModalClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body scrollable={true}>
          <div className="scrollbar" id="style-1">
            <div className="container-fluid">
              {fetchRepLoader ? (
                <>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <i className="text-info">Fetching Record Please Wait..</i>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-2 col-2 p-0">
                      <img
                        alt="image"
                        src="assets/img/ijab-logo.png"
                        width="100px"
                      />
                    </div>
                    <div className="col-md-8 col-8 p-0 report-heading">
                      <h2>International Journal of Agriculture and Biology</h2>
                    </div>
                    <div className="col-md-2 col-2 p-0">
                      {repManuYear != 0 && repManuPno != 0 ? (
                        <>
                          <h5>
                            IJAB-{repManuYear}-{repManuPno}
                          </h5>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                        <h4>{repManuTitle}</h4>
                      </center>
                    </div>
                  </div>

                  {Object.keys(repManuData).length > 0 ? (
                    <>
                      {repManuData.map((m, index) => {
                        return (
                          <>
                            <hr />
                            <Table
                              bordered
                              size="sm"
                              className="border border-dark"
                            >
                              <tbody>
                                <tr className="bg-dark">
                                  <td className="text-center" colSpan="2">
                                    {m.manu_file != null ? (
                                      <a
                                        href={
                                          window.storage +
                                          "downloadManuscript/" +
                                          m.manu_file
                                        }
                                        download={m.manu_file}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-white"
                                      >
                                        {/* <i className="fas fa-file-download"></i>Manu File */}
                                        <i className="fas fa-file-download"></i>{" "}
                                        {m.manu_file.substring(
                                          0,
                                          m.manu_file.lastIndexOf(".")
                                        )}
                                      </a>
                                    ) : (
                                      <i>No File</i>
                                    )}
                                    {m.file_status == 2 ? (
                                      <>
                                        <span class="text-white">
                                          {" "}
                                          (Eligible)
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                                {m.me_comments == null ||
                                m.me_comments == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Managing Editor
                                      </td>
                                      <td colSpan={5}>
                                        <b>Comments:</b> {m.me_comments}
                                        <br />
                                        {m.me_file != null ? (
                                          <a
                                            href={
                                              window.storage +
                                              "downloadReply/" +
                                              m.me_file
                                            }
                                            download={m.me_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                            Download Reply File
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {m.ret_se_comments == null ||
                                m.ret_se_comments == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <center>
                                          <b>Comments to Author</b>
                                        </center>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Section Editor
                                      </td>
                                      <td colSpan={5}>
                                        <b>Comments:</b> {m.ret_se_comments}
                                        <br />
                                        {m.ret_se_file != null ? (
                                          <a
                                            href={
                                              window.storage +
                                              "downloadReply/" +
                                              m.ret_se_file
                                            }
                                            download={m.ret_se_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                            Download Reply File
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {m.edi_comments == null ||
                                m.edi_comments == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <center>
                                          <b>Comments to Author</b>
                                        </center>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Editorial Office
                                      </td>
                                      <td colSpan={5}>
                                        <b>Comments:</b> {m.edi_comments}
                                        <br />
                                        {m.edi_file != null ? (
                                          <a
                                            href={
                                              window.storage +
                                              "downloadReply/" +
                                              m.edi_file
                                            }
                                            download={m.edi_file}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                            Download Reply File
                                          </a>
                                        ) : (
                                          <a></a>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {m.se_accept == null || m.se_accept == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={6}>
                                        <center>
                                          <b>Comments to M.E</b>
                                        </center>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "12%" }}>
                                        Section Editor
                                      </td>
                                      <td colSpan={5}>
                                        {m.se_file != null ? (
                                          <>
                                            <a
                                              href={
                                                window.storage +
                                                "downloadReply/" +
                                                m.se_file
                                              }
                                              download={m.se_file}
                                              rel="noreferrer"
                                              target="_blank"
                                            >
                                              <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                              Download Reply File
                                            </a>
                                            <br />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        <b>Accept Paper? </b>({m.se_accept})
                                        <br />
                                        <b>Comments:</b> {m.se_comments}
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {Object.keys(m.assign_data).length > 0 ? (
                                  <>
                                    <tr>
                                      <th style={{ width: "9%" }}>
                                        Reviewed By
                                      </th>
                                      <th colSpan={5}>
                                        <center>Decision</center>
                                      </th>
                                    </tr>
                                    {m.assign_data.map((e, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              <b>
                                                {e.rev_fname}{" "}
                                                {e.rev_lname !== "s"
                                                  ? e.rev_lname
                                                  : ""}
                                              </b>
                                              <br />
                                              {e.rev_affiliation}
                                              <br />
                                              {e.created_at ? (
                                                e.created_at.substr(0, 10)
                                              ) : (
                                                <></>
                                              )}
                                              {e.assign_status == 3 ? (
                                                <>
                                                  {e.cer_status == 1 ? (
                                                    <>
                                                      <span className="text-success">
                                                        Certificate Issed
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        onClick={() =>
                                                          issueCertificate(
                                                            e.assign_id,
                                                            repManuId
                                                          )
                                                        }
                                                        className="btn btn-success btn-sm"
                                                        disabled={cerLoader}
                                                      >
                                                        {cerLoader
                                                          ? "Issuing.."
                                                          : "Issue Certificate"}
                                                      </button>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                            <td colSpan={5}>
                                              {e.assign_status == 0 ? (
                                                <>
                                                  <i className="text-danger">
                                                    Reviewer Rejected Manuscript
                                                  </i>
                                                </>
                                              ) : e.assign_status == 1 ? (
                                                <>
                                                  <i className="text-warning">
                                                    Pending for Acceptance
                                                  </i>
                                                </>
                                              ) : e.assign_status == 2 ? (
                                                <>
                                                  <i className="text-info">
                                                    Decision Pending
                                                  </i>
                                                </>
                                              ) : e.assign_status == 3 ? (
                                                <>
                                                  <div className="row">
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Do you want get credit
                                                        for reviewing this
                                                        manuscript?
                                                      </b>{" "}
                                                      ({e.paid})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Subjects and contents
                                                        are suitable for
                                                        publications?
                                                      </b>{" "}
                                                      ({e.q1})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Novelty of results is
                                                        appropriate?
                                                      </b>{" "}
                                                      ({e.q2})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Conclusions are well
                                                        proven?
                                                      </b>{" "}
                                                      ({e.q3})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>Title is suitable?</b>{" "}
                                                      ({e.q4})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Number and quality of
                                                        references are
                                                        appropriate?
                                                      </b>{" "}
                                                      ({e.q5})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        The linguistic quality
                                                        of paper is good?
                                                      </b>{" "}
                                                      ({e.q6})
                                                    </div>
                                                    <div className="col-sm-6">
                                                      <b>
                                                        Reference is provided
                                                        where data are reported?
                                                      </b>{" "}
                                                      ({e.q7})
                                                    </div>
                                                  </div>
                                                  <hr />
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <b>Recomendations: </b>
                                                      {e.r === "r1" ? (
                                                        <>
                                                          <span>
                                                            Accept in Present
                                                            Form
                                                          </span>
                                                        </>
                                                      ) : e.r === "r2" ? (
                                                        <>
                                                          <span>
                                                            Consider After Minor
                                                            Revision
                                                          </span>
                                                        </>
                                                      ) : e.r === "r3" ? (
                                                        <>
                                                          <span>
                                                            Reassess After Major
                                                            Revision
                                                          </span>
                                                        </>
                                                      ) : e.r === "r4" ? (
                                                        <>
                                                          <span>Reject</span>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                    <div className="col-md-6">
                                                      {e.reply_file_path !=
                                                      null ? (
                                                        <a
                                                          href={
                                                            window.storage +
                                                            "downloadRevReply/" +
                                                            e.reply_file_path
                                                          }
                                                          download={
                                                            e.reply_file_path
                                                          }
                                                          rel="noreferrer"
                                                          target="_blank"
                                                          style={{
                                                            color: "#000000",
                                                          }}
                                                        >
                                                          <i className="bi bi-file-earmark-arrow-down-fill"></i>{" "}
                                                          Download Reviewer
                                                          Reply File
                                                        </a>
                                                      ) : (
                                                        <a></a>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <hr />
                                                  <div className="row">
                                                    <div className="col-sm-12">
                                                      <b>
                                                        Confidential Comments to
                                                        Executive Editor:{" "}
                                                      </b>
                                                      {e.ex_co}
                                                    </div>
                                                    <div className="col-sm-12">
                                                      <b>
                                                        Comments to the Author:{" "}
                                                      </b>
                                                      {e.aut_co}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <i>Not Replied Yet</i>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>No Data</td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </Table>
                            {/* <hr /> */}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>No Data</td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={reviewerShow} size="xl" onHide={reviewerModalClose}>
        <Modal.Header>
          <Modal.Title>Reviewer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              {reviewerLoader ? (
                <>Fetching Loader ... </>
              ) : (
                <table className="table table-striped">
                  <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Affiliation</th>
                    <th>Country</th>
                  </thead>

                  {Object.keys(manuscriptReviewers).length > 0 ? (
                    manuscriptReviewers.map((r) => (
                      <tbody>
                        <tr>
                          <td>{r.name}</td>
                          <td>{r.email}</td>
                          <td>{r.affiliation}</td>
                          <td>{r.country_name}</td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <></>
                  )}
                </table>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={reviewerModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <div className="row mt-1">
          <div className="col-sm-3">
            <div className="form-group">
              <input
                type="date"
                className="form-control form-control-sm"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <input
                type="date"
                className="form-control form-control-sm"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <button
              type="button"
              onClick={manuscriptDataDateWise}
              className="btn btn-info btn-sm"
            >
              Get Record
            </button>
          </div>
        </div>
      </div>
      {rejManuListLoader ? (
        <>
          <i>Fetching Record Please Wait....</i>
        </>
      ) : (
        <>
          {Object.keys(rejManuscript).length > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-sm table-striped table-bordered se-pro-manu">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Title / Author</th>
                      <th>File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rejManuscript.map((m) => (
                      <tr key={m}>
                        <td>
                          {m.manu_year == 0 && m.manu_pno == 0 ? (
                            m.manu_id
                          ) : (
                            <>{"IJAB-" + m.manu_year + "-" + m.manu_pno}</>
                          )}
                          <br />
                          {m.manu_date.substr(0, 10)}
                          <br />
                          <span
                            className="icon-property-info"
                            title="View Reviewing Report"
                            onClick={() =>
                              repModalShow(
                                m.manu_id,
                                m.manu_file_id,
                                m.manu_title,
                                m.manu_year,
                                m.manu_pno
                              )
                            }
                          >
                            Report
                          </span>
                        </td>
                        <td>
                          {m.manu_title}
                          <br />
                          <div className="row">
                            <div className="col-md-12">
                              <span
                                className="icon-hand"
                                onClick={() =>
                                  authorModalShow(m.manu_authors, m.manu_id)
                                }
                              >
                                <i className="fas fa-user"></i>
                                {Object.keys(m.manu_authors).length > 0 ? (
                                  m.manu_authors.map((a) => (
                                    <> {a.author_name},</>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </span>
                              {/* <span
                                onClick={() => reviewerModalShow(m.manu_id)}
                                className="icon-hand text-info"
                                style={{ float: "right" }}
                              >
                                Suggested Reviewers
                              </span> */}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <b>Corresponding: </b>
                              {m.manu_user_email}*
                            </div>
                            <div className="col-md-6">
                              <div className="float-end">
                                {Object.keys(m.manu_se).length > 0 ? (
                                  <b>Processed By: </b>
                                ) : (
                                  <>
                                  <span className="text-danger">
                                      Rejected before review process
                                    </span>
                                  </>
                                )}
                                {Object.keys(m.manu_se).length > 0 ? (
                                  m.manu_se.map((s) => (
                                    <>
                                      <i
                                        className={
                                          s.se_manu_status == 0
                                            ? "text-danger"
                                            : s.se_manu_status == 3
                                            ? "text-success"
                                            : s.se_manu_status == 2
                                            ? "text-info"
                                            : ""
                                        }
                                      >
                                        {s.se_name}
                                      </i>

                                      <br />
                                    </>
                                  ))
                                ) : (
                                  <>
                                    
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href={
                              window.storage +
                              "downloadManuscript/" +
                              m.manu_file
                            }
                            target="_blank"
                            rel="noreferrer"
                            download={m.manu_file}
                          >
                            <i
                              style={{ fontSize: "20px" }}
                              className="fas fa-file-download"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <i className="text-danger">No Data</i>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RejManu;

import React, { useState } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Nav from 'react-bootstrap/Nav'
import AllUsers from './manageuser-comp/AllUsers'
import Reviewers from './manageuser-comp/Reviewers'
import SectionEditors from './manageuser-comp/SectionEditors'


const ManageUser = () => {

    const [key, setKey] = useState('AllUsers')

    async function changeComponent(compName) {
        setKey(compName)
    }

    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>User Management</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Nav variant="tabs" defaultActiveKey="link-1">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-1" onClick={() => changeComponent('AllUsers')}>Users</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-8" onClick={() => changeComponent('Reviewers')}>Reviewers</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-9" onClick={() => changeComponent('SectionEditors')}>Section Editors</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-md-12'>
                                                    {(key === 'AllUsers') ?
                                                        <>
                                                            <AllUsers />
                                                        </> :
                                                        (key === 'Reviewers') ?
                                                            <>
                                                                <Reviewers />
                                                            </> :
                                                            (key === 'SectionEditors') ?
                                                                <>
                                                                    <SectionEditors />
                                                                </> :
                                                                <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default ManageUser
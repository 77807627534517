import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const name = window.sessionStorage.getItem("name")
    const navigate = useNavigate()


    useEffect(() => {
        if (!window.sessionStorage.getItem("id") || !window.sessionStorage.getItem("type") || !window.sessionStorage.getItem("name") || !window.sessionStorage.getItem("email")) {
            navigate('/Login')
        }
    }, [])



    function logOut() {
        sessionStorage.removeItem('id')
        sessionStorage.removeItem('type')
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('email')
        navigate('/Login')
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
                <div className="form-inline mr-auto">
                    <ul className="navbar-nav mr-3">
                        <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg
									collapse-btn"> <i data-feather="align-justify"></i></a></li>
                        <li><a href="#" className="nav-link nav-link-lg fullscreen-btn">
                            <i data-feather="maximize"></i>
                        </a></li>
                        <li>
                            <h2>International Journal of Agriculture and Biology</h2>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav navbar-right">
                    <li className="dropdown"><a href="#" data-toggle="dropdown"
                        className="nav-link dropdown-toggle nav-link-lg nav-link-user"> <img alt="image" src="149071.png"
                            className="user-img-radious-style" /> <span className="d-sm-none d-lg-inline-block"></span></a>
                        <div className="dropdown-menu dropdown-menu-right pullDown">
                            <div className="dropdown-title">{name}</div>
                            {/* <a href="profile.html"
                                className="dropdown-item has-icon">
                                <i className="far fa-user"></i> Profile
                            </a>
                            <a href="timeline.html" className="dropdown-item has-icon">
                                <i className="fas fa-bolt"></i>Activities
                            </a>
                            <a href="#" className="dropdown-item has-icon">
                                <i className="fas fa-cog"></i>Settings
                            </a> */}
                            <div className="dropdown-divider"></div>
                            <a onClick={logOut} className="dropdown-item has-icon text-danger">
                                <i className="fas fa-sign-out-alt"></i>Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Header
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-confirm-alert/src/react-confirm-alert.css";

const PublicationManu = () => {
  const [pubManuListLoader, setPubManuListLoader] = useState(false);

  const [pubManuscript, setPubManuscript] = useState([]);
  const [manuscriptAuthors, setManuscriptAuthors] = useState([]);

  const [authorShow, setAuthorShow] = useState(false);
  const authorModalClose = () => setAuthorShow(false);
  const authorModalShow = (authors) => {
    setManuscriptAuthors(authors);
    setAuthorShow(true);
  };

  async function sePubManuscriptData() {
    try {
      setPubManuListLoader(true);
      let datares = await fetch(window.api + "getAllMePubManuAdmin");
      datares = await datares.json();
      if (datares) {
        setPubManuscript(datares.publication_manu);
        setPubManuListLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    sePubManuscriptData();
  }, []);

  const [manuscriptSe, setManuscriptSe] = useState([]);
  const [manuscriptRev, setManuscriptRev] = useState([]);

  const [detailShow, setDetailShow] = useState(false);
  const detailModalClose = () => {
    setDetailShow(false);
    setManuscriptSe([]);
    setManuscriptRev([]);
  };
  const detailModalShow = (se, rev) => {
    setManuscriptSe(se);
    setManuscriptRev(rev);
    setDetailShow(true);
  };

  return (
    <>
      <Modal show={authorShow} onHide={authorModalClose}>
        <Modal.Header>
          <Modal.Title>Author Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <table className="table table-striped">
                <thead>
                  <th>Name</th>
                  <th>Affiliation</th>
                </thead>
                {Object.keys(manuscriptAuthors).length > 0 ? (
                  manuscriptAuthors.map((ad) => (
                    <tbody>
                      <tr>
                        <td>{ad.author_name}</td>
                        <td>{ad.author_affiliation}</td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <></>
                )}
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={authorModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={detailShow} size="xl" onHide={detailModalClose}>
        <Modal.Header>
          <Modal.Title>Review Process Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-12">
              <center>
                <h1>Section Editor</h1>
              </center>
              <table className="table table-striped table-sm">
                <thead>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Affiliation</th>
                </thead>
                {Object.keys(manuscriptSe).length > 0 ? (
                  manuscriptSe.map((se) => (
                    <tbody>
                      <tr>
                        <td>{se.se_name}</td>
                        <td>{se.se_email}</td>
                        <td>{se.se_affiliation}</td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <></>
                )}
              </table>
              <center>
                <h1>Reviewers</h1>
              </center>
              <table className="table table-striped table-sm">
                <thead>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Affiliation</th>
                </thead>
                {Object.keys(manuscriptRev).length > 0 ? (
                  manuscriptRev.map((rev) => (
                    <tbody>
                      <tr>
                        <td>{rev.rev_name}</td>
                        <td>{rev.rev_email}</td>
                        <td>{rev.rev_affiliation}</td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <></>
                )}
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={detailModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {pubManuListLoader ? (
        <>
          <i>Fetching Record Please Wait....</i>
        </>
      ) : (
        <>
          {Object.keys(pubManuscript).length > 0 ? (
            <>
              <div className="table-responsive">
                <table className="table table-sm table-striped table-bordered se-pro-manu">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Title / Author</th>
                      <th>File</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pubManuscript.map((m) => (
                      <tr key={m}>
                        <td>
                          {m.manu_year == 0 && m.manu_pno == 0 ? (
                            m.manu_id
                          ) : (
                            <>{"PJAS-" + m.manu_year + "-" + m.manu_pno}</>
                          )}
                          <br />
                          {m.manu_date.substr(0, 10)}
                        </td>
                        <td>
                          {m.manu_title}
                          <br />
                          <span
                            className="icon-hand"
                            onClick={() => authorModalShow(m.manu_authors)}
                          >
                            <i className="fas fa-user"></i>
                            {m.manu_id < 14245 ? (
                              <>
                                {m.manu_prvious_authors === null ? (
                                  <></>
                                ) : (
                                  <>
                                    {m.manu_prvious_authors.map(
                                      (pa) =>
                                        pa.firstName + " " + pa.lastName + ", "
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {Object.keys(m.manu_authors).length > 0 ? (
                                  m.manu_authors.map((a) => (
                                    <> {a.author_name},</>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </span>
                        </td>
                        <td>
                          {m.final_file ? (
                            <a
                              href={
                                window.storage +
                                "downloadFinalFile/" +
                                m.final_file
                              }
                              target="_blank"
                              rel="noreferrer"
                              download={m.final_file}
                            >
                              <i
                                style={{ fontSize: "20px" }}
                                className="fas fa-file-download"
                              ></i>
                            </a>
                          ) : (
                            <>no file</>
                          )}
                          {/* {(m.manu_file_id > 16100) ?
                                                        <>
                                                            <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                            </a>
                                                        </> :
                                                        <>
                                                            {(m.manu_file) ?
                                                                <>
                                                                    <a href={window.storage + "downloadManuscript/" + m.manu_file.substring(20)} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                                    </a>
                                                                </> :
                                                                <></>}
                                                        </>} */}
                          {/* <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                    </a> */}
                        </td>
                        <td>
                          <i
                            className="text-info icon-hand"
                            onClick={() =>
                              detailModalShow(m.manu_se, m.manu_rev)
                            }
                          >
                            View
                          </i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <i className="text-danger">No Data</i>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PublicationManu;
